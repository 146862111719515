export default function Footer() {
    return (
        // <footer>
        //     <p>
        //         Designed and Developed by
        //         <a href="https://amit-roy.me" target="_blank" rel="noreferrer"> <b>Amit Roy</b>
        //         </a>
        //     </p>
        //     <div className='dis-terms'>
        //         <a href="/disclaimer">Disclaimer</a>
        //         <span></span>
        //         <a href="/terms-and-conditions">Terms and Conditions</a>
        //     </div>
        // </footer>
        <footer>
            <div className="footer-details">
                <div className="company-details">
                    <h3>royFinds</h3>
                    <p>
                        Your trusted partner in mutual fund investments. We help you make informed investment decisions with expert guidance and personalized solutions.
                    </p>
                </div>
                <div className="company-contact">
                    <h4>Contact</h4>
                            <a href="mailto:dipakslg@hotmail.com">
                                dipakslg@hotmail.com
                            </a>
                            <a href="tel:+919434359853">
                                +91 9434359853
                            </a>
                            <p>Siliguri, West Bengal</p>
                </div>
                <div className="company-legal">
                    <h4>Legal</h4>
                        <p>ARN: 267402</p>
                        <p>EDIN: E500537</p>
                </div>
            </div>
            <hr />
            <div className="footer-bottom">
                    <p className="copyright">© {new Date().getFullYear()} royFinds. All rights reserved.</p>
                    <div className="disc-tnc">
                        <a href="/disclaimer">Disclaimer</a>
                        <a href="/terms-and-conditions">Terms & Conditions</a>
                    </div>
            </div>
                <div className="developer-details">
                        <a href="https://amit-roy.me">Designed and Developed by{' '}Amit Roy</a>
                </div>
        </footer>
    );
}